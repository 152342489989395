import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import BreadcrumbProps from "./BreadcrumbProps";

const Breadcrumb = loadable(
    (): Promise<DefaultComponent<BreadcrumbProps>> =>
        import(/* webpackChunkName: "Breadcrumb" */ "./Breadcrumb"),
);
const BreadcrumbLoader = (props: BreadcrumbProps): ReactElement => (
    <Breadcrumb {...props} />
);
export default BreadcrumbLoader;
